<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <!-- 业务类型 -->
      <div class="content-main">
        <div class="order">
          <div class="order-type">业务类型</div>
          <el-radio-group v-model="radio" @change="changeRadio" disabled>
            <el-radio :label="1">内转</el-radio>
            <el-radio :label="2">直拨</el-radio>
            <el-radio :label="3">外发</el-radio>
          </el-radio-group>
        </div>
      </div>

      <!-- 交单信息 -->
      <div v-if="radio !== 3" class="order-box" style="margin-top: 24px; padding-top: 18px;">
        <div class="title" style="margin-left: 24px">交单信息</div>
        <div style="margin-top:17px;width:100%;height:1px;background:rgba(232,232,232,1);"></div>
        <div style="margin: 12px 0px 0px 24px;">
          <el-form :inline="true" label-position="left" label-width="120px">
            <el-row :gutter="40">
              <el-col :span="6">
                <el-form-item label="交单人:">
                  <span class="content">{{orderName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="手机号码:">
                  <span class="content">{{phone}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="备注:">
                  <span class="content">{{deliveryRemark}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="交单图片:" style="width: 100%">
                  <span v-for="(item, idx) in bigImgList" :key="idx">
                    <el-image
                      v-if="item.imageUrl !== null"
                      :preview-src-list="imgSrcList"
                      :src="bigImages[idx]"
                      fit="cover"
                      style="margin-left: 10px;width: 200px; height: 100px;border:1px solid #ddd;border-radius: 4px"
                      @click="onPreview(idx)"
                      lazy
                    ></el-image>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- 外发--下单信息 -->
      <div v-if="radio == 3" class="order-box" style="margin-top: 24px; padding-top: 18px;">
        <div class="title" style="margin-left: 24px">下单信息</div>
        <div style="margin-top:17px;width:100%;height:1px;background:rgba(232,232,232,1);"></div>
        <div style="margin: 12px 0px 0px 24px;">
          <el-form :inline="true" label-position="left" label-width="120px">
            <el-row :gutter="40">
              <el-col :span="6">
                <el-form-item label="下单人:">
                  <span class="content">{{orderName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="手机号码:">
                  <span class="content">{{phone}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="备注:">
                  <span class="content">{{cargoShipmentRemark}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="提货单图片:">
                  <span v-for="(item, idx) in bigImgList" :key="idx">
                    <el-image
                      v-if="item.imageUrl !== null"
                      :preview-src-list="imgSrcList"
                      :src="bigImages[idx]"
                      fit="cover"
                      style="margin-left: 10px;width: 200px; height: 100px;border:1px solid #ddd;border-radius: 4px"
                      @click="onPreview(idx)"
                      lazy
                    ></el-image>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>

      <!-- 必填选填项 -->
      <div class="content-main" style="margin-top: 24px">
        <!-- 内转 -->
        <div>
          <el-form
            v-if="radio == 1"
            :model="form_n"
            :rules="n_rules"
            ref="form_n"
            :inline="true"
            label-position="left"
            label-width="120px"
          >
            <el-row :gutter="40">
              <!-- 必填 -->
              <el-col :span="24">
                <div class="title" style="margin:0 0 15px;">必填</div>
              </el-col>
              <el-col :span="6">
                <el-form-item label="客户名称" prop="partnerId">
                  <el-select
                    v-model="form_n.partnerId"
                    placeholder="请选择"
                    filterable
                    @change="selectPartner"
                  >
                    <el-option
                      v-for="(item, idx) in partnerList"
                      :key="idx"
                      :label="item.partnerShorthand"
                      :value="item.partnerId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="结算客户" prop="partnerAccountId">
                  <el-select v-model="form_n.partnerAccountId" placeholder="请选择" filterable>
                    <el-option
                      v-for="(item, idx) in partnerList"
                      :key="idx"
                      :label="item.partnerShorthand"
                      :value="item.partnerId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="运输日期" prop="transportTime">
                  <el-date-picker
                    v-model="form_n.transportTime"
                    clearable
                    type="date"
                    value-format="timestamp"
                    placeholder="选择日期"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="驾驶员" prop="driverId">
                  <el-select
                    v-model="form_n.driverId"
                    disabled
                    placeholder="请选择"
                    filterable
                    @change="selectOne"
                  >
                    <el-option
                      v-for="(item, idx) in driverList"
                      :key="idx"
                      :label="item.nameType"
                      :value="item.driverId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="提货车号" prop="plateNumber">
                  <el-select
                    v-model="form_n.plateNumber"
                    placeholder="请选择"
                    filterable
                    @change="selectOne"
                  >
                    <el-option
                      v-for="(item, idx) in carInfoList"
                      :key="idx"
                      :label="item.plateNumber"
                      :value="item.plateNumber"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="到货车号" prop="cargoArrivalNumber">
                  <el-input v-model="form_n.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="品名" prop="category">
                  <el-select
                    v-model="form_n.category"
                    placeholder="请选择"
                    filterable
                    @change="selectOne"
                  >
                    <el-option
                      v-for="(item, idx) in $enums.CompanyList.list"
                      :key="idx"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="运费(元)" prop="earningFee">
                  <el-input
                    v-model="form_n.earningFee"
                    placeholder="请输入"
                    maxlength="7"
                    @blur="validityNumber2"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 选填 -->
              <el-col :span="24">
                <div
                  class="title"
                  style="margin:10px 0 15px;padding-top:20px; border-top: 1px dashed #ddd"
                >选填</div>
              </el-col>
              <el-col :span="6">
                <el-form-item label="体积(立方米)" prop="volume">
                  <el-input
                    v-model="form_n.volume"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="件数" prop="categoryPiece">
                  <el-input
                    v-model="form_n.categoryPiece"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber2"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="提货单号" prop="cargoDeliveryNumber">
                  <el-input v-model="form_n.cargoDeliveryNumber" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="货位" prop="cargoLocation">
                  <el-input v-model="form_n.cargoLocation" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="去向" prop="cargoDirection">
                  <el-input v-model="form_n.cargoDirection" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="保管员" prop="keeper">
                  <el-input v-model="form_n.keeper" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="货运员" prop="freightAgent">
                  <el-input v-model="form_n.freightAgent" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="提成系数(%)" prop="driverBonusFee">
                  <el-input
                    v-model="form_n.driverBonusFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="备注" prop="orderRemark">
                  <el-input v-model="form_n.orderRemark" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <!-- 直拨 -->
        <div>
          <el-form
            v-if="radio == 2"
            :model="form_z"
            :rules="z_rules"
            ref="form_z"
            :inline="true"
            label-position="left"
            label-width="120px"
          >
              <!-- 必填 -->
            <el-row :gutter="40">
              <el-col :span="24">
                <div class="title" style="margin: 0 0 15px;">必填</div>
              </el-col>

              <!-- 电子规则子类： 类别选择 -->
              <el-col :span="6">
                <el-form-item label="客户名称" prop="partnerId">
                  <el-select
                    v-model="form_z.partnerId"
                    placeholder="请选择"
                    filterable
                    @change="selectPartner_z"
                  >
                    <el-option
                      v-for="(item, idx) in partnerList"
                      :key="idx"
                      :label="item.partnerShorthand"
                      :value="item.partnerId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="结算客户" prop="partnerAccountId">
                  <el-select v-model="form_z.partnerAccountId" placeholder="请选择" filterable>
                    <el-option
                      v-for="(item, idx) in partnerList"
                      :key="idx"
                      :label="item.partnerShorthand"
                      :value="item.partnerId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="运输日期" prop="transportTime">
                  <el-date-picker
                    v-model="form_z.transportTime"
                    clearable
                    type="date"
                    value-format="timestamp"
                    placeholder="选择日期"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="驾驶员" prop="driverId">
                  <el-select
                    v-model="form_z.driverId"
                    disabled
                    placeholder="请选择"
                    filterable
                    @change="selectOne"
                  >
                    <el-option
                      v-for="(item, idx) in driverList"
                      :key="idx"
                      :label="item.nameType"
                      :value="item.driverId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="提货车号" prop="plateNumber">
                  <el-select
                    v-model="form_z.plateNumber"
                    placeholder="请选择"
                    filterable
                    @change="selectOne"
                  >
                    <el-option
                      v-for="(item, idx) in carInfoList"
                      :key="idx"
                      :label="item.plateNumber"
                      :value="item.plateNumber"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="到货车号" prop="cargoArrivalNumber">
                  <el-input v-model="form_z.cargoArrivalNumber" placeholder="请输入" maxlength="7"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="中转单号" prop="cargoTransferNumber">
                  <el-input v-model="form_z.cargoTransferNumber" placeholder="请输入" maxlength="64"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="品名" prop="category">
                  <el-select
                    v-model="form_z.category"
                    placeholder="请选择"
                    filterable
                    @change="selectOne"
                  >
                    <el-option
                      v-for="(item, idx) in $enums.CompanyList.list"
                      :key="idx"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="运费(元)" prop="earningFee">
                  <el-input
                    v-model="form_z.earningFee"
                    placeholder="请输入"
                    maxlength="7"
                    @blur="validityNumber2"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="拼车费(元)" prop="carpoolingFee">
                  <el-input
                    v-model="form_z.carpoolingFee"
                    placeholder="请输入"
                    maxlength="7"
                    @blur="validityNumber2"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="提成系数(%)" prop="driverBonusFee">
                  <el-input
                    v-model="form_z.driverBonusFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 地址 -->

              <el-col :span="24">
                <div
                  class="title"
                  style="margin:10px 0 15px;padding-top:20px; border-top: 1px dashed #ddd"
                >地址</div>
              </el-col>
              <el-col :span="6">
                <el-form-item label="发货人" prop="senderName">
                  <el-input v-model="form_z.senderName" placeholder="请输入">
                    <el-button
                      slot="append"
                      icon="el-icon-notebook-1"
                      @click="openAddressList(1)"
                    >地址簿</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="发货人手机号" prop="senderContactPhone">
                  <el-input v-model="form_z.senderContactPhone" type="number" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="起运地" prop="adminRegions">
                  <el-cascader
                    v-model="form_z.adminRegions"
                    size="small"
                    :options="regionData"
                    @change="handleRegionChange"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="详细地址(发)" prop="sendAddressDetail">
                  <el-input v-model="form_z.sendAddressDetail" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="收货人" prop="receiverName">
                  <el-input v-model="form_z.receiverName" placeholder="请输入">
                    <el-button
                      slot="append"
                      icon="el-icon-notebook-1"
                      @click="openAddressList(2)"
                    >地址簿</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="收货人手机号" prop="receiverContactPhone">
                  <el-input v-model="form_z.receiverContactPhone" type="number" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="目的地" prop="adminRegions_r">
                  <el-cascader
                    v-model="form_z.adminRegions_r"
                    size="small"
                    :options="regionData"
                    @change="handleRegionChangeR"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="详细地址(收)" prop="receiveAddressDetail">
                  <el-input v-model="form_z.receiveAddressDetail" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <!-- 选填 -->
              <el-col :span="24">
                <div
                  class="title"
                  style="margin:10px 0 15px;padding-top:20px; border-top: 1px dashed #ddd"
                >选填</div>
              </el-col>

              <el-col :span="6">
                <el-form-item label="件数" prop="categoryPiece">
                  <el-input
                    v-model="form_z.categoryPiece"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="体积(立方米)" prop="volume">
                  <el-input
                    v-model="form_z.volume"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="重量(吨)" prop="categoryWeight">
                  <el-input
                    v-model="form_z.categoryWeight"
                    type="number"
                    placeholder="请输入"
                    @change="changeWeight"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
             <el-col :span="6">
                <el-form-item label="成本运费(元)" prop="costCarrierFee">
                  <el-input
                    v-model="form_z.costCarrierFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="提货单号" prop="cargoDeliveryNumber">
                  <el-input v-model="form_z.cargoDeliveryNumber" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item label="货位" prop="warehouseLocation">
                  <el-input v-model="form_z.warehouseLocation" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="6">
                <el-form-item label="资源号" prop="resourceId">
                  <el-input v-model="form_z.resourceId" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="6">
                <el-form-item label="规格" prop="specification">
                  <el-input v-model="form_z.specification" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="型号" prop="steelModel">
                  <el-input v-model="form_z.steelModel" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="钢种" prop="steelType">
                  <el-input v-model="form_z.steelType" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="产地" prop="steelProduction">
                  <el-input v-model="form_z.steelProduction" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item label="开单员" prop="partOrder">
                  <el-input v-model="form_z.partOrder" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="6">
                <el-form-item label="收货员" prop="receivingClerk">
                  <el-input v-model="form_z.receivingClerk" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="6">
                <el-form-item label="理货员" prop="tallyClerk">
                  <el-input v-model="form_z.tallyClerk" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="6">
                <el-form-item label="审核员" prop="checker">
                  <el-input v-model="form_z.checker" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="6">
                <el-form-item label="装卸班组" prop="loadUploadCrew">
                  <el-input v-model="form_z.loadUploadCrew" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="6">
                <el-form-item label="吊装费(元)" prop="liftingFee">
                  <el-input
                    v-model="form_z.liftingFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="备注" prop="orderRemark">
                  <el-input v-model="form_z.orderRemark" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <!-- 外发 -->
        <div>
          <el-form
            v-if="radio == 3"
            :model="form_w"
            :rules="w_rules"
            ref="form_w"
            :inline="true"
            label-position="left"
            label-width="120px"
          >
            <el-row :gutter="40">
              <!-- 必填 -->
              <el-col :span="24">
                <div class="title" style="margin:0 0 15px;">必填</div>
              </el-col>
              <el-col :span="6">
                <el-form-item label="客户名称" prop="partnerId">
                  <el-select
                    v-model="form_w.partnerId"
                    placeholder="请选择"
                    filterable
                    @change="selectPartner_w"
                  >
                    <el-option
                      v-for="(item, idx) in partnerList"
                      :key="idx"
                      :label="item.partnerShorthand"
                      :value="item.partnerId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="结算客户" prop="partnerAccountId">
                  <el-select
                    v-model="form_w.partnerAccountId"
                    placeholder="请选择"
                    filterable
                    @change="selectPartner_ww"
                  >
                    <el-option
                      v-for="(item, idx) in partnerList"
                      :key="idx"
                      :label="item.partnerShorthand"
                      :value="item.partnerId"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="运输日期" prop="transportTime">
                  <el-date-picker
                    v-model="form_w.transportTime"
                    clearable
                    type="date"
                    value-format="timestamp"
                    placeholder="选择日期"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="品名" prop="category">
                  <el-select
                    v-model="form_w.category"
                    placeholder="请选择"
                    filterable
                    @change="selectOne"
                  >
                    <el-option
                      v-for="(item, idx) in $enums.CompanyList.list"
                      :key="idx"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="吨数" prop="categoryWeight">
                  <el-input
                    v-model="form_w.categoryWeight"
                    type="number"
                    placeholder="请输入"
                    @change="changeWeight"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="收入单价(元)" prop="earningUnitPrice">
                  <el-input
                    v-model="form_w.earningUnitPrice"
                    type="number"
                    placeholder="请输入"
                    @change="changeEarningUnitPrice"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="收入金额(元)" prop="earningFee">
                  <el-input
                    v-model="form_w.earningFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="成本单价(元)" prop="costUnitPrice">
                  <el-input
                    v-model="form_w.costUnitPrice"
                    type="number"
                    placeholder="请输入"
                    @change="changeCostUnitPrice"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="成本运费(元)" prop="costCarrierFee">
                  <el-input
                    v-model="form_w.costCarrierFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 地址 -->
              <el-col :span="24">
                <div
                  class="title"
                  style="margin:10px 0 15px;padding-top:20px; border-top: 1px dashed #ddd"
                >地址</div>
              </el-col>
              <el-col :span="6">
                <el-form-item label="发货人" prop="senderName">
                  <el-input v-model="form_w.senderName" placeholder="请输入">
                    <el-button 
                    slot="append" 
                    icon="el-icon-notebook-1" 
                    @click="openAddressList(1)"
                    >地址簿</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="发货人手机号" prop="senderContactPhone">
                  <el-input v-model="form_w.senderContactPhone" type="number" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="起运地" prop="adminRegions">
                  <el-cascader
                    v-model="form_w.adminRegions"
                    size="small"
                    :options="regionData"
                    @change="handleRegionChange"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="详细地址(发)" prop="sendAddressDetail">
                  <el-input v-model="form_w.sendAddressDetail" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="收货人" prop="receiverName">
                  <el-input v-model="form_w.receiverName" placeholder="请输入">
                    <el-button 
                    slot="append" 
                    icon="el-icon-notebook-1" 
                    @click="openAddressList(2)"
                    >地址簿</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="收货人手机号" prop="receiverContactPhone">
                  <el-input v-model="form_w.receiverContactPhone" type="number" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="目的地" prop="adminRegions_r">
                  <el-cascader
                    v-model="form_w.adminRegions_r"
                    size="small"
                    :options="regionData"
                    @change="handleRegionChangeR"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="详细地址(收)" prop="receiveAddressDetail">
                  <el-input v-model="form_w.receiveAddressDetail" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>

              <!-- 选填 -->
              <el-col :span="24">
                <div
                  class="title"
                  style="margin:10px 0 15px;padding-top:20px; border-top: 1px dashed #ddd"
                >选填</div>
              </el-col>
              <el-col :span="6">
                <el-form-item label="体积(立方米)" prop="volume">
                  <el-input
                    v-model="form_w.volume"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="件数" prop="categoryPiece">
                  <el-input
                    v-model="form_w.categoryPiece"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="吊装费(元)" prop="liftingFee">
                  <el-input
                    v-model="form_w.liftingFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="过磅费(元)" prop="weightFee">
                  <el-input
                    v-model="form_w.weightFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="卸车费(元)" prop="unloadFee">
                  <el-input
                    v-model="form_w.unloadFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="过路过桥费(元)" prop="tollsFee">
                  <el-input
                    v-model="form_w.tollsFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="罚款(元)" prop="fine">
                  <el-input
                    v-model="form_w.fine"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="加油费(元)" prop="fuelFee">
                  <el-input
                    v-model="form_w.fuelFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="提成系数(%)" prop="driverBonusFee">
                  <el-input
                    v-model="form_w.driverBonusFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="其他费用(元)" prop="otherFee">
                  <el-input
                    v-model="form_w.otherFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="信息费(元)" prop="messageFee">
                  <el-input
                    v-model="form_w.messageFee"
                    type="number"
                    placeholder="请输入"
                    @blur="validityNumber"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="备注" prop="orderRemark">
                  <el-input v-model="form_w.orderRemark" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>

      <div class="tac mt40px">
        <el-button type="primary" @click="create_order" :loading="submitLoading">确认</el-button>
        <el-button
          style="margin-left: 30px"
          @click="$router.push('/consign-order/consign-order')"
        >返回</el-button>
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      top="none"
      width="65%"
      center
    >
     <component v-bind:is="targetComponent" @visible="isVisible" :dialogParams="dialogParams"></component>
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  components: {
    AddressList: () => import("./address-list"),
  },
  data() {
      const rules = {
        partnerId: {
          required: true,
          message: "请选择客户名称",
          trigger: "change",
        },
        partnerAccountId: {
          required: true,
          message: "请选择结算客户",
          trigger: "change",
        },
        transportTime: {
          required: true,
          message: "请选择运输日期",
          trigger: "change",
        },
        driverId: {
          required: true,
          message: "请选择驾驶员",
          trigger: "change",
        },
        plateNumber: {
          required: true,
          message: "请选择提货车号",
          trigger: "change",
        },
        cargoArrivalNumber: [
          { required: true, message: "请输入到货车号", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
        category: { required: true, message: "请选择品名", trigger: "change" },
        earningFee: [
          { required: true, message: "请输入运费或收入金额", trigger: "blur" },
        ],
        
      };
    return {
      nav: [
        { name: "托运录单", path: "/consign-order/consign-order" },
        { name: "录单" },
      ],
      bigImgList:[],
      bigImages:[],
      imgSrcList:[],
      // 弹窗参数
      targetComponent: null,
      dialogVisible: false,
      dialogTitle: null,
      dialogParams: null,
      // dialogKey: 0,

      submitLoading: false,
      orderName: null,
      phone: null,
      deliveryRemark: null,
      cargoShipmentRemark: null,
      imageUrl: null, // https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png
      zxsArray: ["北京市", "天津市", "上海市", "重庆市"],
      regionData: regionData,
      radio: 1,
      value1: "",
      orderType: "内转",
      partnerList: [],
      driverList: [],
      carInfoList: [],
      imageInfoVOS: [],
      form_n: {
        orderType: "内转",
        partnerId: null,
        partnerAccountId: null,
        transportTime: null,
        driverId: null,
        plateNumber: null,
        cargoArrivalNumber: null,
        category: null,
        earningFee: null,
        cargoDeliveryNumber: null,
        categoryPiece: null,
        volume: null,
        cargoLocation: null,
        cargoDirection: null,
        keeper: null,
        freightAgent: null,
        driverBonusFee: null,
        orderRemark: null,
      },
      form_z: {
        orderType: "直拨",
        partnerId: null,
        partnerAccountId: null,
        transportTime: null,
        driverId: null,
        plateNumber: null,
        cargoArrivalNumber: null,
        cargoTransferNumber: null,
        category: null,
        earningFee: null,
        carpoolingFee: null,
        cargoDeliveryNumber: null,
        categoryPiece: null,
        volume: null,
        warehouseLocation: null,
        resourceId: null,
        specification: null,
        steelModel: null,
        steelType: null,
        steelProduction: null,
        partOrder: null,
        receivingClerk: null,
        tallyClerk: null,
        checker: null,
        loadUploadCrew: null,
        liftingFee: null,
        driverBonusFee: null,
        orderRemark: null,

         senderName: null, //发货人
        senderContactPhone: null, //发货人手机号
        adminRegions: null, //起运地
        sendAddressDetail: null, //详细地址(发)
        receiverName: null, //收货人
        receiverContactPhone: null, //收货人手机号
        adminRegions_r: null, // 目的地
        receiveAddressDetail: null, //详细地址(收)
        sendProvinceName: null,
        sendCityName: null,
        sendCountyName: null,
        receiveProvinceName: null, //
        receiveCityName: null,
        receiveCountyName: null,

        costCarrierFee: null, //成本运费(元)
        categoryWeight: null, //吨数
      },
      form_w: {
        orderType: "外发",
        adminRegions: [],
        adminRegions_r: [],
        partnerId: null,
        partnerAccountId: null,
        transportTime: null,
        sendProvinceName: null,
        sendCityName: null,
        sendCountyName: null,
        sendAddressDetail: null,
        senderName: null,
        senderContactPhone: null,
        receiveProvinceName: null,
        receiveCityName: null,
        receiveCountyName: null,
        receiveAddressDetail: null,
        receiverName: null,
        receiverContactPhone: null,
        category: null,
        categoryWeight: null,
        earningUnitPrice: null,
        earningFee: null,
        costUnitPrice: null,
        costCarrierFee: null,
        categoryPiece: null,
        volume: null,
        liftingFee: null,
        weightFee: null,
        unloadFee: null,
        tollsFee: null,
        fine: null,
        fuelFee: null,
        driverBonusFee: null,
        otherFee: null,
        messageFee: null,
        orderRemark: null,
      },
      n_rules: {
          ...rules,
      },
      // 直拨
      z_rules: {
          ...rules,
        

        // 直拨
        cargoTransferNumber: [
          { required: true, message: "请输入中转单号", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
        carpoolingFee: [
          { required: true, message: "请输入拼车费", trigger: "blur" },
        ],
        driverBonusFee:[
          { required: true, message: '请输入提成系数费', trigger: 'blur' },
          { validator: this.$validator.isTwoDecimal },
        ]

      },

        // 外发
        w_rules:{
            ...rules,
        adminRegions: {
          required: true,
          message: "请选择起运地",
          trigger: "change",
          validator: (rule, value, callback)=>{
            console.log(value)
            if(!value[0]){
              return callback(new Error('请选择起运地'));
            }else {
              callback();
            }
          }          
        },
        sendAddressDetail: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
        senderName: [
          { required: true, message: "请输入发货人", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
        senderContactPhone: [
          { required: true, message: "请输入发货人手机号", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
        adminRegions_r: {
          required: true,
          message: "请选择目的地",
          trigger: "change",
          validator: (rule, value, callback)=>{
            console.log(value)
            if(!value[0]){
              return callback(new Error('请选择目的地'));
            }else {
              callback();
            }
          }
        },
        receiveAddressDetail: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
        receiverName: [
          { required: true, message: "请输入收货人", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
        receiverContactPhone: [
          { required: true, message: "请输入收货人手机号", trigger: "blur" },
          { validator: this.$validator.isContainBlank },
        ],
        categoryWeight: [
          { required: true, message: "请输入吨数", trigger: "blur" },
          { validator: this.$validator.isThreeDecimal },
        ],
        earningUnitPrice: [
          { required: true, message: "请输入收入单价", trigger: "blur" },
          { validator: this.$validator.isTwoDecimal },
        ],
        costUnitPrice: [
          { required: true, message: "请输入成本单价", trigger: "blur" },
          { validator: this.$validator.isTwoDecimal },
        ],
        costCarrierFee: [
          { required: true, message: "请输入成本运费", trigger: "blur" },
          { validator: this.$validator.isTwoDecimal },
        ],
      },
    };
  },
  methods: {
    // 依次查看大图
    onPreview(index){
      let arr1 = this.bigImages.filter((arrItem, arrIndex) => index <= arrIndex);
      let arr2 = this.bigImages.filter((arrItem, arrIndex) => index > arrIndex);
      let arr3 = arr2.sort((a, b) => {
        b - a;
      });
      this.imgSrcList = [...arr1, ...arr3];
    },


    openAddressList(isSend) {
      this.targetComponent = "AddressList";
      this.dialogVisible = true;
      this.dialogTitle = "地址簿";
      this.dialogParams = isSend;
      // this.dialogKey++;
    },
    isVisible(val, isSend) {
      // （发）
      this.dialogVisible = false;
      let form = '';
      if(this.radio === 2){
          form = 'form_z';
      }else if(this.radio === 3){
          form = 'form_w';
      }
      if (isSend / 1 === 1) {
        this[form].senderName = val.contacter;
        this[form].senderContactPhone = val.contacterPhone;
        this[form].adminRegions = val.area;
        this[form].sendAddressDetail = val.addressDetail;
        
        this[form].sendProvinceName= val.area[0]?CodeToText[val.area[0]]: null;
        this[form].sendCityName= val.area[1]?CodeToText[val.area[1]]:null;
        this[form].sendCountyName= val.area[2]?CodeToText[val.area[2]]:null;
      }
      // （收）
      if (isSend / 1 === 2) {
        this[form].receiverName = val.contacter;
        this[form].receiverContactPhone = val.contacterPhone;
        this[form].adminRegions_r = val.area;
        this[form].receiveAddressDetail = val.addressDetail;

        this[form].receiveProvinceName= val.area[0]?CodeToText[val.area[0]]: null;
        this[form].receiveCityName= val.area[1]?CodeToText[val.area[1]]: null;
        this[form].receiveCountyName= val.area[2]?CodeToText[val.area[2]]: null;
      }
    },

    /**
     * 规范数据输入框
     */
    validityNumber2(val) {
      console.log(val);
      let tempVal = val.target.value;
      const reg = /^[.0-9]*$/;
      if (!reg.test(tempVal) && tempVal != "") {
        this.$message.error("请输入规范数字");
      }
    },
    
    /**
     * 变更吨数自动计算
     * 收入金额(元)
     * 成本运费(元)
     */
    changeWeight(val) {
      console.log("吨数变化..." + val);
      this.changeEarningUnit(val);
      this.changeCostUnit(val);
    },
    changeEarningUnit(n) {
      console.log(this.form_w.earningUnitPrice);
      if (!this.form_w.earningUnitPrice) {
        return;
      } else {
        let tempVal = parseFloat(this.form_w.earningUnitPrice * n).toFixed(3);
        this.form_w.earningFee = tempVal.substring(0, tempVal.length - 1);
        console.log("收入金额：" + this.form_w.earningFee);
      }
    },
    changeCostUnit(n) {
      console.log(this.form_w.costUnitPrice);
      if (!this.form_w.costUnitPrice) {
        return;
      } else {
        let tempVal = parseFloat(this.form_w.costUnitPrice * n).toFixed(3);
        this.form_w.costCarrierFee = tempVal.substring(0, tempVal.length - 1);
        console.log("成本运费：" + this.form_w.costCarrierFee);
      }
    },
    /**
     * 计算收入金额
     */
    changeEarningUnitPrice(val) {
      let tempVal = parseFloat(this.form_w.categoryWeight * val).toFixed(3);
      this.form_w.earningFee = tempVal.substring(0, tempVal.length - 1);
    },
    /**
     * 计算成本运费
     */
    changeCostUnitPrice(val) {
      let tempVal = parseFloat(this.form_w.categoryWeight * val).toFixed(3);
      this.form_w.costCarrierFee = tempVal.substring(0, tempVal.length - 1);
    },
    /**
     * 规范数据输入框
     */
    validityNumber(val) {
      this.$utils.validityNumber(
        this,
        val.target.validationMessage.length,
        val.target.value
      );
    },
    /**
     * 内转、直拨、外发
     */
    changeRadio(val) {
      if (val == 1) {
        console.log("内转");
        this.orderType = "内转";
        this.radio = 1;
      } else if (val == 2) {
        console.log("直拨");
        this.orderType = "直拨";
        this.radio = 2;
      } else {
        console.log("外发");
        this.orderType = "外发";
        this.radio = 3;
      }
    },
    selectOne() {
      console.log("选择");
    },
    /**
     * 选择客户名称
     * 并自动关联结算客户
     */
    selectPartner(val) {
      this.form_n.partnerAccountId = val;
    },
    selectPartner_z(val) {
      this.form_z.partnerAccountId = val;
    },
    selectPartner_w(val) {
      this.form_w.partnerAccountId = val;
    },
    selectPartner_ww(val) {
      console.log(val);
    },
    /**
     * 保存并继续
     */
    sava_and_continue() {
      console.log("sava_and_continue");
    },
    /**
     * 创建订单
     */
    create_order() {
      var radio = this.radio;
      var _this = this;
      console.log(this.$refs);
      switch (radio) {
        case 1:
          _this.$refs.form_n.validate(async (valid) => {
            if (!valid) return;
            _this.submitLoading = true;
            try {
              _this.onSuccess_n();
              // eslint-disable-next-line no-empty
            } catch (e) {}
            _this.submitLoading = false;
          });
          break;
        case 2:
          _this.$refs.form_z.validate(async (valid) => {
            if (!valid) return;
            _this.submitLoading = true;
            try {
              _this.onSuccess_z();
              // eslint-disable-next-line no-empty
            } catch (e) {}
            _this.submitLoading = false;
          });
          break;
        case 3:
          _this.$refs.form_w.validate(async (valid) => {
            if (!valid) return;
            _this.submitLoading = true;
            try {
              _this.onSuccess_w();
              // eslint-disable-next-line no-empty
            } catch (e) {}
            _this.submitLoading = false;
          });
          break;

        default:
          break;
      }
    },
    /**
     * 录单-内转
     */
    async onSuccess_n() {
      await this.$api.recordOrder({
        isInsert: 0,
        steelOrderInfoVO: this.form_n,
      });
      this.$notify({
        title: "成功",
        message: "录单成功",
        type: "success",
      });
      this.$router.push("/consign-order/consign-order");
    },
    /**
     * 录单-直拨
     */
    async onSuccess_z() {
      await this.$api.recordOrder({
        isInsert: 0,
        steelOrderInfoVO: this.form_z,
      });
      this.$notify({
        title: "成功",
        message: "录单成功",
        type: "success",
      });
      this.$router.push("/consign-order/consign-order");
    },
    /**
     * 录单-外发
     */
    async onSuccess_w() {
      await this.$api.recordOrder({
        isInsert: 0,
        steelOrderInfoVO: this.form_w,
      });
      this.$notify({
        title: "成功",
        message: "录单成功",
        type: "success",
      });
      this.$router.push("/consign-order/consign-order");
    },
    /**
     * 起运地省市区
     */
    handleRegionChange(value) {
      var arr = value;
      if (!value) return;
      let form = '';
      if(this.radio === 2){
          form = 'form_z';
      }else if(this.radio === 3){
          form = 'form_w';
      }
      this[form].sendProvinceName = arr[0]?CodeToText[arr[0]]:null;
      this[form].sendCityName = arr[1]?CodeToText[arr[1]]:null;
      this[form].sendCountyName = arr[2]?CodeToText[arr[2]]:null;
    },
    /**
     * 目的地
     */
    handleRegionChangeR(value) {
      var arr = value;
      if (!value) return;
      let form = '';
      if(this.radio === 2){
          form = 'form_z';
      }else if(this.radio === 3){
          form = 'form_w';
      }
      this[form].receiveProvinceName = arr[0]?CodeToText[arr[0]]:null;
      this[form].receiveCityName = arr[1]?CodeToText[arr[1]]:null;
      this[form].receiveCountyName = arr[2]?CodeToText[arr[2]]:null;
    },
  },
  watch: {
    // "form_w.partnerAccountId": function(){
    //   console.log('结算客户变化...')
    // }
  },
  async mounted() {
    var pList = [];
    pList = await this.$api.shortList({});
    this.partnerList = pList;

    var cList = [];
    cList = await this.$api.carInfoList({});
    this.carInfoList = cList.data;

    var dList = [];
    dList = await this.$api.driverFastList({});
    this.driverList = dList;

    var form;
    var orderType = this.$route.query.orderType;
    this.orderName = this.$route.query.orderName;
    this.phone = this.$route.query.phone;
    // 内转、直拨  外发的备注字段获取逻辑不同
    try {
      this.deliveryRemark = this.$route.query.deliveryRemark;
    } catch (error) {}
    try {
      this.cargoShipmentRemark = this.$route.query.cargoShipmentRemark;
    } catch (error) {}

    var orderId = this.$route.query.orderId;
    if (orderType === "内转") {
      this.radio = 1;
      form = await this.$api.OrderGet({
        orderId: orderId,
      });
      // 无法联动的问题
      this.$set(form, "partnerAccountId", null);
      this.$set(form, "partnerId", null);
      this.form_n = form;
    } else if (orderType === "直拨") {
      this.radio = 2;
      form = await this.$api.OrderGet({
        orderId: orderId,
      });
      // 无法联动的问题
      this.$set(form, "partnerAccountId", null);
      this.$set(form, "partnerId", null);
      this.form_z = form;
    } else {
      this.radio = 3;
      form = await this.$api.OrderGet({
        orderId: orderId,
      });
      // 无法联动的问题
      this.$set(form, "partnerAccountId", null);
      this.$set(form, "partnerId", null);
      this.form_w = form;

      // 对起运地、目的地的直辖市特殊处理
      var provinceCode;
      var cityCode;
      var countyCode;
      var sendProvinceName = this.form_w.sendProvinceName;

      var receiveProvinceCode;
      var receiveCityCode;
      var receiveCountyCode;
      var receiveProvinceName = this.form_w.receiveProvinceName;

      // 对宁乡市单独处理
      if (this.form_w.sendCountyName === "宁乡市") {
        this.form_w.sendCountyName = "宁乡县";
      }

      if (this.form_w.receiveCountyName === "宁乡市") {
        this.form_w.receiveCountyName = "宁乡县";
      }

      try {
        if (this.zxsArray&&this.zxsArray.indexOf(sendProvinceName) !== -1) {
          provinceCode = TextToCode[this.form_w.sendProvinceName].code;
          cityCode = TextToCode[this.form_w.sendProvinceName]["市辖区"].code;
          countyCode =
            TextToCode[this.form_w.sendProvinceName]["市辖区"][
              this.form_w.sendCountyName
            ].code;
        } else {
          provinceCode = TextToCode[this.form_w.sendProvinceName].code;
          cityCode =
            TextToCode[this.form_w.sendProvinceName][this.form_w.sendCityName]
              .code;
          countyCode =
            TextToCode[this.form_w.sendProvinceName][this.form_w.sendCityName][
              this.form_w.sendCountyName
            ].code;
        }
        this.form_w.adminRegions = [provinceCode, cityCode, countyCode];
        console.log(1);
        if (this.zxsArray &&this.zxsArray.indexOf(receiveProvinceName) !== -1) {
          receiveProvinceCode =
            TextToCode[this.form_w.receiveProvinceName].code;
          console.log(this.form_w.receiveCityName);
          receiveCityCode =
            TextToCode[this.form_w.receiveProvinceName]["市辖区"].code;
          console.log(receiveCityCode);
          receiveCountyCode =
            TextToCode[this.form_w.receiveProvinceName]["市辖区"][
              this.form_w.receiveCountyName
            ].code;
        } else {
          receiveProvinceCode =
            TextToCode[this.form_w.receiveProvinceName].code;
          receiveCityCode =
            TextToCode[this.form_w.receiveProvinceName][
              this.form_w.receiveCityName
            ].code;
          receiveCountyCode =
            TextToCode[this.form_w.receiveProvinceName][
              this.form_w.receiveCityName
            ][this.form_w.receiveCountyName].code;
        }
        this.form_w.adminRegions_r = [
          receiveProvinceCode,
          receiveCityCode,
          receiveCountyCode,
        ];
      } catch (error) {
        console.log(error);
      }
    }
    if (Array.isArray(form.imageInfoVOS)) {
      try {
        this.imageInfoVOS = form.imageInfoVOS;
        this.imageUrl = form.imageInfoVOS[0].imageUrl;
        // eslint-disable-next-line no-empty

        form.imageInfoVOS.forEach(item=>{
          if(item.imageUrl.length>=2){
            for (let i of item.imageUrl) {
              this.bigImages.push(i)
                this.bigImgList.push({
                  gmtCreate: item.gmtCreate,
                  gmtModified: item.gmtModified,
                  imageRemark: item.imageRemark,
                  imageType: item.imageType,
                  imageUrl: i,
                  orderId: item.orderId,
                  tenantId: item.tenantId,
                  uploaderPhone: item.uploaderPhone,
                  userIdentity: item.userIdentity,
                  userName: item.userName,
                })
            }
          }else{
            let img = null
            img = item.imageUrl
            this.bigImages.push(img[0])
            this.bigImgList.push(img)
          }
        })

        
      } catch (error) {}
    }
    
  },
  computed:{
  }
};
</script>

<style lang='sass' scoped>
.clearBox
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: rgba(41,41,41,1)
  .content
    font-size: 14px
    font-family: Microsoft YaHei
    font-weight: 400
    color: rgba(102,102,102,1)
  .order-box
    background: rgba(255,255,255,1)
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06)
    border-radius: 4px

/deep/ .el-form-item--mini .el-form-item__label 
    line-height: 34px;
    text-align: right;
/deep/ .el-form-item--mini.el-form-item
    display: flex
    width: 100%
/deep/ .el-select,
/deep/ .el-cascader--small,
/deep/ .el-date-editor.el-input
    width: 100%
/deep/ .el-form-item__content
    flex: 1
/deep/ .el-dialog__wrapper 
    display: flex;
    align-items: center;
/deep/ .el-dialog--center .el-dialog__body
    padding: 0
/deep/ .el-dialog
    margin-bottom: 0
/deep/ .el-input-group__append
    padding: 0 8px

/deep/ .el-image-viewer__close
   color: #fff
/deep/ .el-image-viewer__next,
/deep/ .el-image-viewer__prev
   border: 3px solid #fff
</style>
